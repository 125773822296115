/* Login.css */

.login-form .input-group select {
  position: absolute;
  z-index: 11;
  width: 70px;
  border: none;
  top: 6px;
  height: 33px;
  background-color: #E9E9E9;
  padding: 0 6px 0 6px;
  left: 6px;
  border-radius: 0px;
}

.vh-80 {
  min-height: 80vh;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: #FFF !important;
  color: fieldtext !important;
}

.login-form .input-group .form-select {
  background-position: right 0.18rem center;
}

.login-form .input-group input {
  padding-left: 80px;
}

.login-form .input-group select:focus {
  box-shadow: none;
  border: none;
}

.form-check-input {
  background-color: #D9D9D9;
  border-radius: 0px;
}

.form-check-input:focus {
  border-color: #D9D9D9;
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #E46422;
  border-color: #E46422;
}

.form-check-input[type=checkbox] {
  border-radius: 0px;
}

.form-control {
  height: 45px;
  border-radius: 0px;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #838383;
  font-size: 10px;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #838383;
  font-size: 15px;
}

.form-control::placeholder {
  color: #838383;
  font-size: 15px;
}

.login-logo {
  text-align: center;
}

.login-logo img {
  width: 160px;
}

.btn {
  height: 45px;
  border-radius: 0;
}


body .btn-primary {
  color: #fff;
  background-color: #E46422 !important;
  border-color: #E46422 !important;
}

body .btn-primary:hover {
  color: #fff;
  background-color: #cf5416 !important;
  border-color: #cf5416 !important;
}

body .btn-primary:active {
  --bs-btn-active-bg: #E46422;
  --bs-btn-active-border-color: #E46422;
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

body .btn-outline-secondary {
  color: #838383;
  background-color: transparent;
  background-image: none;
  border-color: #838383;
}

body .btn-outline-secondary:hover {
  color: #fff;
  background-color: #838383;
  border-color: #838383;
}

body .btn-outline-primary {
  color: #E46422;
  background-color: transparent;
  border-color: #E46422;

}

body .btn-outline-primary:hover {
  color: #fff;
  background-color: #E46422;
  border-color: #E46422;
}

.form-control:focus {
  border-color: #cf5416;
  outline: 0;
  box-shadow: none;
}

/* ---------Social button----------- */
.social-button img {
  width: 20px;
  margin-right: 6px;
}

.social-button button {
  color: #043133;
  padding: 0px 20px;
}

.social-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.social-button button {
  color: #043133;
  padding: 0px 20px;
  height: 40px !important;
  border: 1px solid #dadce0 !important;
  width: auto;
  white-space: nowrap;
  font-size: 14px !important;
  box-shadow: none !important;
  background: #fff !important;
  color: #000 !important;
}

.social-button .nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-family: 'Montserrat' !important;

}

.social-button button svg {
  background: #1877f2;
  border: 1px solid #Ccc;
  border-radius: 15px;
  text-align: center;
}

/* ---------React select css--------- */
.reactSelect .css-b62m3t-container {
  position: absolute;
  top: 8px;
  z-index: 11;
  left: 8px;
}

.reactSelect .css-1ttumjp-control {
  min-width: 63px;
}

.reactSelect .css-1kw2fvz-control,
.reactSelect .css-1n2nmei-control {
  max-width: 63px;
}

.reactSelect .css-1hb7zxy-IndicatorsContainer {
  height: 30px;
}

.reactSelect .css-1xc3v61-indicatorContainer,
.reactSelect .css-15lsz6c-indicatorContainer {
  padding: 0px 0px;
}

.reactSelect .css-1fdsijx-ValueContainer {
  padding: 2px 1px;
}

.reactSelect .css-1kw2fvz-control:hover {
  border-color: #cf5416;
}

.reactSelect .css-1kw2fvz-control {
  box-shadow: none;

}

.css-166bipr-Input {
  line-height: 0;
}

.reactSelect .css-hlgwow {
  padding: 2px 0px;
}

/* ------------------ */
@media only screen and (max-width: 991px) {
  .userbg {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .social-button button {
    width: 100%;
  }

}

@media only screen and (max-width: 425px) {
  .social-button {
    display: inline-block;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 30px;
  }

}

.agreebox {
  display: flex;
}

.agreebox a {
  color: #000;
}

