.vehicleDetailsImg img {
    object-fit: cover;
    height: 543px;
    width: 100%;
}

.inner_vehical_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nft_pic.nft_multi img {
    object-fit: cover;
    height: 267px;
    
}
body .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: Montserrat;
}
.vlist li {
    padding: 10px 10px;
    white-space: nowrap;
    font-size: 14px;
    border-bottom: 1px solid #C8C8C8;
    min-width: 150px;
}