.paymentImg img {
    width: 100%;
    height: 220px;
    object-fit: cover;
}

.paypal-button {
    background: #000000!important;
    border-radius: 4px;
    color: #fff!important;
    height: 55px;
    font-size: 21px;
    letter-spacing: 1px;
}