.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    z-index: 0;
    background: #000000;
    opacity: .5;
}

.pl-0 {
    padding-left: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

/* Home.css */
.banner-area {
    min-height: 592px;
    padding: 100px 0px;
}

.banner-location .nav-link {
    font-weight: 600;
}

.banner-location .tab_style2 .nav-pills .nav-link {
    color: #000;
}

.destination-img {
    height: 200px;
    object-position: 50% 50%;
    object-fit: fill;
}

.destination-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap !important;
}

.banner-bg {
    background-size: cover;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;

}

.banner-area .bannerInput .btn {
    position: absolute;
    z-index: 11;
    border: none;
}

.bannerInput input {
    padding-left: 40px;
    border: none;
    outline: none;
    border-right: 1px solid #838383;
}

.bannerHome .banner-location .rmdp-container {
    display: block !important;
}

.section-title-line {
    /* background: #0049A6; */
    background: #E46422;
    margin: 0 auto;
    height: 3px;
    width: 15%;
}

.btnsearch {
    width: 40px;
    max-height: 40px;
    line-height: 0;
}

.add_guest1 {
    color: #838383;
    padding-left: 12px;
}

.add_guest {
    color: #000;
    padding-left: 12px;
}

.guest_dropdown img {
    width: 24px;
    height: 20px;
    object-fit: cover;
}

.add_.guest {
    padding-left: 12px;
}


/* -------Top Destination Start----- */
.boximage {
    position: relative;
    height: 400px;
}

.boximage::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.83%, rgba(1, 0, 0, 0.54) 72.4%, #000000 100%);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    right: 0;
    /* top: 0; */
    height: 244px;
}

.boximage:hover img {
    transform: scale(1.1);
    transition: 1s;
}

.boximage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.boximage h4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 100%;
    font-weight: 800;
    font-size: 28px;
    text-align: center;
}

.boximage h5 {
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 3;
}

/* -------------------- */
.hotel-view img {
    width: 100%;
    height: 300px;
}

.hotel-img:hover img {
    transform: scale(1.1);
    transition: 1s;
}

.like {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

/* -----tab-style1-------- */
.tab-style1 .nav-pills .nav-link.active,
.tab-style1 .nav-pills .show>.nav-link {
    color: #E46422;
    background-color: transparent;
    border-bottom: 2px solid #E46422;
}

.tab-style1 .nav-pills .nav-link {
    border-radius: 0px;
    color: #838383;
    font-size: 20px;
}

/* ------Property Start Css------- */
body .property-area {
    background: url(/public/assets/images/banner/property.svg);
    background-size: cover !important;
    background-repeat: no-repeat;
    min-height: 429px;
    background-position: center;
}

/* .property-area::before {
    background: linear-gradient(180deg, rgba(155, 104, 142, 0) 57.76%, rgba(121, 61, 106, 0.3) 75%, #793D6A 100%);
    content: "";
    position: absolute;
    z-index: 111;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
} */
.bottom_text {
    height: 60px;
    align-items: end;
    position: relative;
    align-items: end;
    z-index: 111;
}

/* --------Suggestion Css Start---------------- */
.suggestionlist .list {
    min-height: 199px;
}

.list .btn {
    padding: 0px;
}

.list.card a:hover {
    color: #E46422;
}

.suggestionlist .list img {
    width: 100%;
    object-fit: cover;
    height: 170px;
}

/* -------------------- */
@media screen and (max-width: 767px) {
    .mainBanner .btnsearch {
        bottom: 8px;
    }
}

@media screen and (max-width: 1199px) {
    .footer-block ul {
        list-style: none;
        float: left;
        padding-left: 0px;
    }
}

@media screen and (max-width: 500px) {
    .tab-style1 .nav-pills .nav-link {
        border-radius: 0px;
        color: #838383;
        font-size: 15px;
    }

    .search_destination {
        min-width: 90%;
    }

    body .px-sm-0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media screen and (max-width: 425px) {
    .banner-location .tab_style2 .nav-pills .nav-link {
        padding: 10px 7px;
    }

    .banner-location {
        min-height: 253px;
    }

    .btnsearch {
        bottom: 17px;
    }
}

@media screen and (max-width: 382px) {
    .banner-location .tab_style2 .nav-pills .nav-link {

        font-size: 14px;
    }
}

@media screen and (max-width: 352px) {
    .banner-location .tab_style2 .nav-pills .nav-link {
        font-size: 13px;
    }
}

@media screen and (max-width: 337px) {
    .banner-location .tab_style2 .nav-pills .nav-link {
        padding: 10px 4px;
        font-size: 13px;
    }
}

@media (max-width:450px) {
    .destination-img {
        height: 100px;
        object-position: 50% 50%;
        object-fit: fill;
    }
}

.abc {
    background-size: cover;
}

.propertyVideo video {
    width: 300px;
}

.overlay {
    background: #000;
    width: 100%;
    height: 100%;
}

.destination-info h2 {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.destination-details h4 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 185.023%;
    /* 37.005px */
}

.good-to-know h2 {

    color: #000;
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    line-height: 185.023%;
    /* 64.758px */
}

.destination-gallery h2 {
    color: #000;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 185.023%;
    /* 64.758px */
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height to center the loader */
}

/* ==================== */
.border_right {
    border-right: 1px solid #838383 !important;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .border_right {
        border-right: none !important;
        border-bottom: 1px solid #d7d7d7 !important;
        margin-bottom: 6px;
    }
}