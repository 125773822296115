img {
    max-width: 100%;
}

section {
    padding: 60px 0px;
}

body .text-primary {
    color: #E46422 !important;
}

body .text-blue {
    color: #0049A6 !important;
}

body .text-navy-blue {
    color: #1F93D4 !important;
}

body .text-dark-blue {
    color: #12B8FF !important;
}

body .text-grey {
    color: #838383;
}

.list-style-none {
    list-style: none;
}

/* ---------Searchfield----------- */
.search_field i {
    position: absolute;
    z-index: 11;
    left: 7px;
    bottom: 0;
    color: #838383;
    top: 5px;
}

.search_field .form-control {
    padding-left: 30px;
}

/* ---------------- */
.hmdZnu {
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

.amenities_list svg,
.amenities_list img {
    height: 30px;
    width: 30px;
    object-fit: contain;
}

.offered_amenities svg {
    width: 33px;
    object-fit: contain;
}

.heart-icon {
    cursor: pointer;
}

.dashboardLink {
    text-decoration: none;
}

.validationErr {
    color: red;
}

.removeUnderline {
    text-decoration: none;
}

.slider__range {
    left: 0% !important;
    width: 100% !important;
}

.reviewRatingShowMore {
    color: #000;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.reviewPopup {
    padding: 20px;
    height: 400px;
    overflow-y: auto;
}

.editRoomTypeImage {
    float: right;
}

.addMoreImgBtn {
    float: right;
    margin-top: -40px;
}

.stars {
    color: #ffd700;
    font-size: 24px;
}

.modal-dialog button.btn-close:focus {
    box-shadow: none;
}


.maincolor1 {
    color: #E46422;
}

.poweredByLogo {
    margin-top: -20px;
}

.unread-circle {
    width: 17px;
    height: 17px;
    background-color: #fff;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 600;
    display: inline-block;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maindatatable .hgByPU div:first-child {
    white-space: inherit;
}

.maindatatable .krVTia div:first-child {
    white-space: inherit;
}

/* ------------- */

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .rmdp-input {
        padding-left: 25px !important;
    }
}