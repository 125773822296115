.mr-0 {
    margin-right: 0px !important;
}

.filter-head {
    padding: 15px;
    border-bottom: 1px solid #d2d2d2;
}

.rangeslider {
    margin-bottom: 45px;
}

.searchfield {
    position: relative;
}

.filter-list,
.searchfield {
    padding: 15px;
}

.searchfield .list {
    height: 250px;
    overflow-y: auto;
}

.searchfield .list {
    height: auto;
    max-height: 182px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 111;
    right: 0;
}

.search_destination {
    position: absolute;
    background: #fff;
    border: 1px solid #dee2e6;
    overflow: hidden;
    min-width: 40%;
    /* margin-top: 12px; */
    padding: 20px 0px;
    z-index: 111;
}

.search_destination .list {
    overflow-y: auto;
    max-height: 300px;
    width: 100%;
}

.search_destination .list ul li {
    padding: 10px 25px;
}

.search_destination .list ul li:hover {
    background-color: #f4f4f4;
    cursor: pointer;
}


.searchfield .list ul li {
    padding: 10px 9px;
}

.searchfield .list ul li:hover {
    background-color: #f4f4f4;
    cursor: pointer;
}

.listcheckbox .form-check-input {
    margin-right: 8px;
}

/* ---------- */
.propertybox {
    position: relative;

}

.propertybox .swiper {
    width: 100%;
    height: 300px;
    cursor: pointer;
}

.propertybox .swiper-slide:hover img {
    transform: scale(1.1);
    transition-timing-function: ease-out !important;
    transition: 0.25s;
}

.propertybox .swiper-slide img {
    display: block;
    width: 100%;
    height: 290px;
    object-fit: cover;
}

.property-img {
    border-radius: 5px;
}

.banner-location {
    border-radius: 50px;
    padding-right: 70px;
}

.propertybox .property-img img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.heart-icon {
    position: absolute;
    top: 8px;
    right: 8px;
}

.propertybox .property-img img:hover {
    transform: scale(1.1);
    transition: 1s;
}

.searchfield .input-group .btn {
    position: absolute;
    right: 0;
    z-index: 11;
}

.searchfield .input-group input {
    border: none;
    border-bottom: 1px solid #dee2e6;
    padding-right: 40px;
}

/* -------------- */
.guest_dropdown .dropdown-menu {
    width: 16rem;
    padding: 18px;
    border-radius: 25px;
}

.guest_dropdown .dropdown-toggle::after {
    display: none;
}

.btn-number {
    border: 1px solid #0049A666;
    height: 30px;
    width: 30px;
    margin-top: 7px;
}

.input-group .input-number {
    width: 30px;
    padding: 0px;
    text-align: center;
}

/* ----------------- */
.bannerHome .banner-location .input-border-none .rmdp-input {
    border-right: none;
}


.banner-location .rmdp-input {
    border-radius: 0px;
    height: 45px;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: 1px solid #838383;
    width: 100%;
    outline: none;
    margin: 1px 0;
    padding: 2px 2px 2px 40px;
}

.banner-location .rmdp-input:focus {
    border: none;
    border-right: 1px solid #cf5416;
    box-shadow: none !important;
    outline: none !important;
}

.banner-location .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #e46422db;
    box-shadow: 0 0 3px #e4642200;
    color: #fff;
}

.banner-location .rmdp-arrow-container:hover {
    background-color: #E46422;
    box-shadow: 0 0 3px #E46422;
}

.banner-location .rmdp-day span {
    border-radius: 2px;
    bottom: 3px;
    font-size: 14px;
    left: 3px;
    position: absolute;
    right: 3px;
    top: 3px;
}

.banner-location .rmdp-day.rmdp-today span {
    background-color: #e46422c9;
    color: #fff;
}

.banner-location .rmdp-week-day {
    color: #272727;
    cursor: default;
    font-size: 13px;
    font-weight: 600;
}

.banner-location .rmdp-arrow {
    border: solid #272727;
    border-width: 0 2px 2px 0;
    display: inline-block;
    height: 8px;
    margin-top: 5px;
    padding: 2px;
    width: 8px;
}

.banner-location .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: #e46422db;
    color: #fff;
}

.viewmore {
    right: 13px;
    z-index: 1;
    bottom: 13px;
}

body .rmdp-range {
    background-color: #e46422;
    box-shadow: 0 0 3px #ffffff00;
    color: #fff;
}

body .banner-location .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: #e46422db;
    color: #fff;
    border-radius: 2px;
}



@media screen and (max-width: 767px) {
    .banner-location .rmdp-top-class {
        display: block !important;
    }

    .banner-location .rmdp-day-picker {
        display: block !important;
    }

    .bannerInput input {
        border-right: none;
        border-bottom: 1px solid #cccccc;

    }

    .banner-location .rmdp-input {
        border-bottom: 1px solid #cccccc;
        border-right: none;
        margin-bottom: 0px;

    }

    .guest_dropdown .dropdown {
        border-bottom: 1px solid #838383;
    }

    .bannerInput button {
        padding: 0px;
    }

    .guest_dropdown .dropdown button {
        padding: 0px;
    }

    .banner-location .rmdp-input:focus {
        border-bottom: 1px solid #cf5416;
        border-right: none;

    }

    .banner-location {
        min-height: auto;
        padding-right: 0px;
    }

}

@media screen and (max-width: 480px) {
    .banner-location .rmdp-top-class {
        display: block !important;
    }

    .banner-location .rmdp-day-picker {
        display: block !important;
    }

    .bannerInput input {
        border-right: none;
        border-bottom: 1px solid #cccccc;

    }

    .banner-location .rmdp-input {
        border-bottom: 1px solid #cccccc;
        border-right: none;
        margin-bottom: 0px;

    }

    .guest_dropdown .dropdown {
        border-bottom: 1px solid #838383;
    }

    .bannerInput button {
        padding: 0px;
    }

    .guest_dropdown .dropdown button {
        padding: 0px;
    }

    .banner-location .rmdp-input:focus {
        border-bottom: 1px solid #cf5416;
        border-right: none;

    }

    .banner-location {
        min-height: auto;
        padding-right: 0px;
    }
}

@media screen and (max-width: 425px) {


    .btnsearch {
        bottom: 17px;
    }
}

@media screen and (max-width: 382px) {}

@media screen and (max-width: 352px) {}

@media screen and (max-width: 333px) {
    .filter-list .listcheckbox .form-check-inline {
        width: 100% !important;
    }

    .banner-location .rmdp-input {
        font-size: 14px;
        padding: 2px 2px 2px 35px;
    }

    .bannerInput input {
        padding-left: 35px;
        font-size: 14px;
    }

    .guest_dropdown .dropdown button {
        font-size: 14px;
    }

    .guest_dropdown .add_guest {
        padding-left: 12px;
    }

}



.bannerInput.input-group.searchbtn button:last-child {
    position: relative;
    float: right;
}

.bannerInput.input-group.searchbtn input {
    border: none;
}